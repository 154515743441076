@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.state-inputs-input-grid {
  max-width: 25rem;
  display: grid;
  grid-template-columns: minmax(auto, 11rem) auto;
  grid-template-rows: .5fr 1fr 1fr 1fr;
  grid-gap: 1em .5rem;
}
.state-inputs-input-grid input {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}
.state-inputs-input-grid input[type="text"] {
  padding-inline-start: .5rem;
  letter-spacing: .5rem;
  max-width: 16rem;
  border-width: 2px;
  border-radius: 0.5rem;
  height: 2.25rem;
}

input.included, input.includedPosition {
  background-color: #c9b458;
}

label[for="included"] {
  grid-area: 1 / 1 / 2 / 3;
}

.includedPositions {
  grid-area: 2 / 1 / 3 / 3;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0em auto;
}

input.includedPosition {
  width: 4rem;
  letter-spacing: .25rem;
}

input.excluded {
  background-color: #787c7e;
}

@media only screen and (max-width: 450px) {
  .state-inputs-input-grid {
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  .state-inputs-input-grid > input {
    width: 100%;
  }
  .state-inputs-position-label {
    grid-area: 3 / 1 / 4 / 3;
  }
  .state-inputs-input-grid {
    max-width: 25rem;
  }
  .state-inputs-positions {
    grid-area: 4 / 1 / 4 / 3;
  }
}

.state-inputs-positions {
  display: flex;
  justify-content: space-between;
  min-width: 16rem;
}

.state-inputs-positions > input {
  background-color: #6aaa64;
  text-align: center;
  border-radius: 0.5em;
  width: 2.5rem;
  height: 2.5rem;
  border-width: 2px;
}


